<template>
    <q-layout view="hHh lpR fff">
        <q-header elevated>
            <HomeNav></HomeNav>
        </q-header>
        <q-page-container>
            <q-page>
                <slot name="offers"></slot>
                <div class="tw-p-5">
                    <slot></slot>
                </div>
                <slot name="most_sold"></slot>
            </q-page>
        </q-page-container>

        <q-footer class="footer">
            <HomeFooter></HomeFooter>
        </q-footer>
    </q-layout>
</template>

<script setup lang="ts">
/**
 *
 *  Props and emits section
 *
 *
 */

/**
 *
 *  Ref, computed and hooks section
 *
 *
 */

const leftDrawerOpen = ref(false)
const main_menu_filtered = useLeftMenu()
const shopCartStore = useShopCartStore()
const initSettingsStore = useDefaultSettingsStore()
const { get } = useOFetchCustom("init-settings")
const { data } = useLazyAsyncData(
    "init-settings",
    () => {
        return new Promise((resolve, reject) => {
            shopCartStore.fetchShopCart()
            get(undefined, {
                onSuccess: (res: any) => {
                    initSettingsStore.setSettings(res.data)
                    resolve(res)
                },
                onError: (err) => reject(err),
            })
        })
    },
    {
        server: false,
        immediate: true,
    }
)

/**
 *
 *  Life cicle events
 *
 *
 */
</script>

<style>
.shadown {
    box-shadow: 0px 4px 4px 0px rgba(6, 116, 191, 0.15);
}

.body-blur {
    filter: blur(3px);
}
</style>
